//colors
$color-purple: #7000FE;
$color-blurple: #676cfa;
$color-text: #4e5155;


body {
    background-color: #f4f4fd;
    color: #2c2c2c;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
    color: $color-text;
}


.page__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 20px;

    .page__title {
        font-size: 24px;
        color: black;
        font-weight: 500;
    }
}

@media (max-width: 576px) {
    .page__header {
        .page__title {
            flex-grow: 1;
            font-size: 18px;
            text-align: center;
        }

        button,
        a {
            width: 100%;
        }
    }
}