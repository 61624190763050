/* Styles for the popup container */
.popup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    overflow-y: auto;
}

/* Styles for the popup content */
.popup-content {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: center;
    width: 80%;
    max-width: 400px;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.product-info {
    margin-bottom: 20px;
}

.product-info img {
    width: 100px;
    height: auto;
}

.product-info h2 {
    margin: 10px 0;
    font-size: 1.5rem;
}

.product-info p {
    margin: 5px 0;
}

.quantity-section {
    margin-bottom: 20px;
}

.quantity-section input {
    width: 100%;
    padding: 10px;
}

.button-section {
    display: flex;
    justify-content: space-between;
}

.button-section button {
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.add-to-cart-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    margin-right: 10px;
}

.close-button {
    background-color: #ccc;
}

.stock_action,
.stock_implementation {
    color: #000;
    margin-bottom: 20px;
}

.stock_action label,
.stock_implementation label {
    padding: 10px;
    border-radius: 10px;
    background-color: #f3f3f3;
    border: 2px solid #d5d5d5;
    font-weight: 500;
    accent-color: #007bff;

    &:has(input[type="radio"]:checked) {
        color: #007bff;
        border-color: #007bff;
    }

    p {
        margin-bottom: 0;
    }
}

.overlay-active {
    overflow: hidden;
}


//
.stock_action {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;

    label {
        p {
            margin-bottom: 0;
        }
    }
}

.stock_implementation {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
}

@media (max-width: 480px) {

    .stock_action,
    .stock_implementation {
        margin-bottom: 30px;

        label {
            display: flex;
            gap: 12px;
        }
    }

    .stock_action {
        grid-template-columns: 1fr;

    }

    .stock_implementation {
        grid-template-columns: 1fr;
    }

    #overlay_add_to_cart {
        .button-section {
            flex-direction: column-reverse;
            gap: 12px;
        }
    }
}