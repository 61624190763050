//sidemenu
#layout-menu {
  background-color: #26272f !important;
  width: 300px;

  .menu-inner {
    & > li {
      width: 300px;
    }
  }
}

.mobilemenu {
  //box-shadow: 0 1px 4px rgba(146, 161, 176, 0.15);
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  margin-bottom: 20px;

  @media (min-width: 1200px) {
    display: none;
  }
}

.menu-vertical.layout-menu {
  .menu_close {
    cursor: pointer;
    display: none;
  }
  .menu_close i {
    font-size: 30px;
  }

  @media (max-width: 1199.98px) {
    transition: all ease-in-out 0.15s;

    &.active {
      transform: translate(0);
    }

    .menu_close {
      display: block;
      font-weight: 500;
      color: #fff;
      text-align: right;
      width: 100%;
    }

    .app-brand-link {
      display: none;
    }
  }
}

.menu-header {
  .menu-header-text {
    color: #dcdcdc;
  }
}

.header__name {
  color: #dcdcdc;
  font-weight: 500;
  display: block;
  padding: 0.625rem 1rem;
  text-align: center;
}

.menu__trigger {
  position: relative;
  display: flex;
  gap: 12px;
  cursor: pointer;
  align-items: center;

  span {
    font-weight: 500;
  }

  .hamburger-lines {
    display: block;
    height: 22px;
    width: 26px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .line {
      display: block;
      height: 4px;
      width: 100%;
      border-radius: 10px;
      background: #0e2431;
    }

    .line1 {
      transform-origin: 0% 0%;
      transition: transform 0.4s ease-in-out;
    }

    .line2 {
      transition: transform 0.2s ease-in-out;
    }

    .line3 {
      transform-origin: 0% 100%;
      transition: transform 0.4s ease-in-out;
    }
  }
}

.menu-vertical .menu-inner > .menu-item .menu-link {
  margin: 0;
}

.warehouse_cont {
  padding: 0.625rem 1rem;

  select {
    color: #dcdcdc;
    background-color: #26272f;
    border-color: #919191;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='#dcdcdc' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  }
}

.menu-link {
  color: #dcdcdc;
  font-weight: 500;
  transition: all ease-in-out 0.15s !important;
  border-radius: 0 !important;

  svg {
    width: 20px;
    fill: #7a7a7a;
    margin-right: 10px;
    height: 20px;
    transition: all ease-in-out 0.15s;
  }

  i {
    transition: all ease-in-out 0.15s;
    color: #7a7a7a;
  }

  &:hover {
    color: white !important;
    background-color: #3b3d44 !important;

    i {
      color: white;
    }

    svg {
      fill: white;
    }
  }
}
