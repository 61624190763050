.scanner {
  &__camerarow {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 14px;
  }

  &__video {
    border-radius: 8px;
  }

  &__information {
    margin-bottom: 2em;
    font-weight: 500;

    h1 {
      font-size: 24px;
      color: black;
      font-weight: 500;
    }
  }

  @media (min-width: 992px) {
    &__camerarow {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

//old
/*
* demo.css
* File include item demo only specific css only
******************************************************************************/

.menu .app-brand.demo {
  height: 64px;
  margin-top: 12px;
  justify-content: center;
}

.app-brand-logo.demo svg {
  width: 22px;
  height: 38px;
}

.app-brand-text.demo {
  font-size: 1.75rem;
  letter-spacing: -0.5px;
  text-transform: lowercase;
}

/* ! For .layout-navbar-fixed added fix padding top tpo .layout-page */
/* Detached navbar */
.layout-navbar-fixed
  .layout-wrapper:not(.layout-horizontal):not(.layout-without-menu)
  .layout-page {
  padding-top: 76px !important;
}

/* Default navbar */
.layout-navbar-fixed .layout-wrapper:not(.layout-without-menu) .layout-page {
  padding-top: 64px !important;
}

/* Navbar page z-index issue solution */
.content-wrapper .navbar {
  z-index: auto;
}

/*
* Content
******************************************************************************/

.demo-blocks > * {
  display: block !important;
}

.demo-inline-spacing > * {
  margin: 1rem 0.375rem 0 0 !important;
}

/* ? .demo-vertical-spacing class is used to have vertical margins between elements. To remove margin-top from the first-child, use .demo-only-element class with .demo-vertical-spacing class. For example, we have used this class in forms-input-groups.html file. */
.demo-vertical-spacing > * {
  margin-top: 1rem !important;
  margin-bottom: 0 !important;
}

.demo-vertical-spacing.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.demo-vertical-spacing-lg > * {
  margin-top: 1.875rem !important;
  margin-bottom: 0 !important;
}

.demo-vertical-spacing-lg.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.demo-vertical-spacing-xl > * {
  margin-top: 5rem !important;
  margin-bottom: 0 !important;
}

.demo-vertical-spacing-xl.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.rtl-only {
  display: none !important;
  text-align: left !important;
  direction: ltr !important;
}

[dir='rtl'] .rtl-only {
  display: block !important;
}

/*
* Layout demo
******************************************************************************/

.layout-demo-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 1rem;
}

.layout-demo-placeholder img {
  width: 900px;
}

.layout-demo-info {
  text-align: center;
  margin-top: 1rem;
}

#products tr td:nth-child(6):not(.disabled):not(.not-after)::after {
  content: '📝';
  margin-right: 5px;
  color: #696cff;
  font-size: 15px;
}

#products tr td:nth-child(6):not(.disabled) {
  cursor: pointer;
}

#products tr td:nth-child(6):not(.disabled):hover {
  transform: scale(1.085);
  /* Ajustez le niveau de zoom selon vos préférences */
}

.center {
  margin: 0 auto;
  text-align: center;
}

#video {
  width: 100%;
  max-width: 640px;
}

.anim-box {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 25px 30px;
  transition: transform 0.6s ease-out;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

/* adds the 4 corners */
.anim-box:before,
.anim-box:after,
.anim-box > :first-child:before,
.anim-box > :first-child:after {
  position: absolute;
  width: 10%;
  height: 15%;
  border-color: white;
  border-style: solid;
  content: ' ';
}

/* top left corner */
.anim-box:before {
  top: 0;
  left: 0;
  border-width: 2px 0 0 2px;
}

/* top right corner */
.anim-box:after {
  top: 0;
  right: 0;
  border-width: 2px 2px 0 0;
}

/* bottom right corner */
.anim-box > :first-child:before {
  bottom: 0;
  right: 0;
  border-width: 0 2px 2px 0;
}

/* bottom left corner */
.anim-box > :first-child:after {
  bottom: 0;
  left: 0;
  border-width: 0 0 2px 2px;
}

.anim-box svg {
  max-width: 230px;
  width: 80%;
  opacity: 0.3;
}

.anim-box .scanner {
  animation-play-state: running;
}

/* animated laser beam */
.scanner {
  width: 100%;
  height: 3px;
  background-color: red;
  opacity: 0.8;
  position: absolute;
  box-shadow: 0px 0px 8px 10px rgba(170, 11, 23, 0.49);
  top: 50%;
  left: 0;
  animation-name: scan;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: paused;
}

@keyframes scan {
  0% {
    box-shadow: 0px 0px 8px 10px rgba(170, 11, 23, 0.49);
    top: 50%;
  }

  25% {
    box-shadow: 0px 6px 8px 10px rgba(170, 11, 23, 0.49);
    top: 5px;
  }

  75% {
    box-shadow: 0px -6px 8px 10px rgba(170, 11, 23, 0.49);
    top: 98%;
  }
}

.managestock-flex {
  position: relative;
  justify-content: center;
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
}

.scanner-animation {
  position: absolute;
  padding: 50px;
  right: 0;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  aspect-ratio: 4 / 3;
}

@media (max-width: 480px) {
  .scanner-animation {
    padding: 16px;
  }
  .menu .app-brand.demo {
    height: auto;
    padding: 0;
  }
}
